import { buildHtmlElement } from '@/js/util/build_html_element';
import TiptapTable from '@tiptap/extension-table';
import TiptapTableCell from '@tiptap/extension-table-cell';
import TiptapTableHeader from '@tiptap/extension-table-header';
import TiptapTableRow from '@tiptap/extension-table-row';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import { Extension } from '@tiptap/core';
import { CellSelection } from '@tiptap/pm/tables';
import { TextSelection } from '@tiptap/pm/state';

const findClosest = (sel) => window.getSelection().anchorNode.parentElement.closest(sel);

const editingTable = (editor) => {
  return editor.isActive('table') && selectedOrFocusedCell();
};

const countSelectedCells = (editor) => {
  const selection = editor.state.selection;

  if (selection instanceof CellSelection) {
    return selection.ranges.length;
  }
  return 0;
};

const canSplitCell = (editor) => {
  const cell = { ...editor.getAttributes('tableCell'), ...editor.getAttributes('tableHeader') };
  const canSplit = cell.colspan > 1 || cell.rowspan > 1;

  return countSelectedCells(editor) <= 1 && canSplit;
};

const willShowCellsMergeMenu = (editor) => {
  const editing = editingTable(editor);
  const manyCellsSelected = countSelectedCells(editor) > 1;
  const mouseIsReleased = !editor.storage.isMousePressed;

  return editing && manyCellsSelected && mouseIsReleased;
};

const willShowCellsSplitMenu = (editor) => {
  const editing = editingTable(editor);
  const cellIsSplitable = canSplitCell(editor);

  return editing && cellIsSplitable;
};

const selectedOrFocusedCell = () => {
  const table = findClosest('table');
  return table?.querySelector('.selectedCell') || findClosest('th, td');
};

const cellsSplitMenuReferenceRectangle = () => {
  return selectedOrFocusedCell().getBoundingClientRect();
};

const rowMenuReferenceRectangle = () => {
  const targetedRow = selectedOrFocusedCell().parentElement;
  return targetedRow?.getBoundingClientRect();
};

const columnMenuReferenceRectangle = () => {
  const targetedCell = selectedOrFocusedCell();
  const targetedRow = targetedCell.parentElement;
  const sameRowCells = Array.from(targetedRow.children);
  const targetedColumnIdx = sameRowCells.findIndex((cell) => cell === targetedCell);
  const table = targetedRow.parentElement.parentElement;
  const targetedColumn = table.querySelectorAll('tr th, tr td')[targetedColumnIdx];

  return targetedColumn?.getBoundingClientRect();
};

const recalculatePositionUponMenuOpen = (instance) => {
  instance._dfMenuOpenStateObserver?.disconnect();

  const menu = instance.popper.querySelector('.flex-dropdown-menu');
  const observer = new MutationObserver((mutations, _) => {
    mutations.length > 0 && instance.popperInstance.forceUpdate();
  });

  observer.observe(menu, { attributes: true });
  instance._dfMenuOpenStateObserver = observer;
};

const rebuildMenuAndObserve = (instance, menu) => {
  instance.setContent(menu);
  recalculatePositionUponMenuOpen(instance);
};

const tableConfiguration = {
  resizable: true,
};

const tableRowsMenuConfig = {
  pluginKey: 'tableRowsContextualMenu',
  shouldShow: ({ editor }) => editingTable(editor),
  element: buildHtmlElement('table-rows-menu'),
  tippyOptions: {
    zIndex: 1,
    placement: 'left',
    theme: 'df-editor',
    getReferenceClientRect: rowMenuReferenceRectangle,
    onCreate: recalculatePositionUponMenuOpen,
    onShow: (instance) => rebuildMenuAndObserve(instance, buildHtmlElement('table-rows-menu')),
  },
};

const tableColumnsMenuConfig = {
  pluginKey: 'tableColumnsContextualMenu',
  shouldShow: ({ editor }) => editingTable(editor),
  element: buildHtmlElement('table-columns-menu'),
  tippyOptions: {
    zIndex: 1,
    placement: 'top',
    theme: 'df-editor',
    getReferenceClientRect: columnMenuReferenceRectangle,
    onCreate: recalculatePositionUponMenuOpen,
    onShow: (instance) => rebuildMenuAndObserve(instance, buildHtmlElement('table-columns-menu')),
  },
};

const tableCellsMergeMenuConfig = {
  pluginKey: 'tableCellsMergeMenu',
  shouldShow: ({ editor }) => willShowCellsMergeMenu(editor),
  element: buildHtmlElement('table-cells-merge-menu'),
  tippyOptions: {
    zIndex: 1,
    placement: 'bottom',
    theme: 'df-editor',
    onShow: (instance) => instance.setContent(buildHtmlElement('table-cells-merge-menu')),
  },
};

const tableCellsSplitMenuConfig = {
  pluginKey: 'tableCellsSplitMenu',
  shouldShow: ({ editor }) => willShowCellsSplitMenu(editor),
  element: buildHtmlElement('table-cells-split-menu'),
  tippyOptions: {
    zIndex: 1,
    placement: 'bottom',
    theme: 'df-editor',
    getReferenceClientRect: cellsSplitMenuReferenceRectangle,
    onShow: (instance) => instance.setContent(buildHtmlElement('table-cells-split-menu')),
  },
};

const MouseTracker = Extension.create({
  name: 'MouseTracker',
  addStorage: () => ({ isMousePressed: false }),
  onCreate({ editor }) {
    const editorPage = editor.view.dom;

    const storeMousePressed = () => {
      editor.storage.isMousePressed = true;
    };

    const storeMouseReleaseAndForceRefresh = () => {
      editor.storage.isMousePressed = false;

      const { state, view } = editor;
      const anySelection = TextSelection.create(state.doc, state.selection.from);
      const unselect = state.tr.setSelection(anySelection);
      const restoreSelection = state.tr.setSelection(state.selection);

      view.dispatch(unselect);
      view.dispatch(restoreSelection);
    };

    editorPage.addEventListener('mousedown', storeMousePressed);
    editorPage.addEventListener('mouseup', storeMouseReleaseAndForceRefresh);
  },
});

const isWithinBounds = (inner, outer) => {
  const innerRect = inner.getBoundingClientRect();
  const outerRect = outer.getBoundingClientRect();

  return (
    innerRect.top >= outerRect.top &&
    innerRect.left >= outerRect.left &&
    innerRect.bottom <= outerRect.bottom &&
    innerRect.right <= outerRect.right
  );
};

const HideMenusOnScroll = Extension.create({
  name: 'HideMenusOnScroll',
  onCreate({ editor }) {
    const editorPageContainer = editor.view.dom.parentNode;
    const application = editorPageContainer.closest('[role="application"]');
    const handleMenusVisibility = () => {
      requestAnimationFrame(() => {
        const hideOnScrollSources = application.querySelectorAll('[data-js-visibility-trigger]');
        const selector = '[data-js-visibility-target]';

        for (const el of hideOnScrollSources) {
          const hideOnScrollTarget = el.closest(selector) || el;
          const hide = !isWithinBounds(el, editorPageContainer);

          hideOnScrollTarget?.classList.toggle('hidden', hide);
        }
      });
    };

    editorPageContainer.addEventListener('click', handleMenusVisibility);
    editorPageContainer.addEventListener('scroll', handleMenusVisibility);
    document.addEventListener('wheel', handleMenusVisibility);
    document.addEventListener('onwheel', handleMenusVisibility);
  },
});

const TableRowMenu = BubbleMenu.extend({ name: 'TableRowMenu' });
const TableColumnMenu = BubbleMenu.extend({ name: 'TableColumnMenu' });
const TableCellMergeMenu = BubbleMenu.extend({ name: 'TableCellMergeMenu' });
const TableCellSplitMenu = BubbleMenu.extend({ name: 'TableCellSplitMenu' });
const tableExtensions = [
  MouseTracker,
  HideMenusOnScroll,
  TiptapTable.configure(tableConfiguration),
  TiptapTableCell,
  TiptapTableHeader,
  TiptapTableRow,
  TableRowMenu.configure(tableRowsMenuConfig),
  TableColumnMenu.configure(tableColumnsMenuConfig),
  TableCellMergeMenu.configure(tableCellsMergeMenuConfig),
  TableCellSplitMenu.configure(tableCellsSplitMenuConfig),
];

export default tableExtensions;
