/* globals initTooltips */
import escapeRegExp from 'lodash.escaperegexp';

const replaceAll = (string, substitutions) => {
  const replacement = (result, [key, value]) => {
    return result.split(`{{${key}}}`).join(escapeRegExp(value));
  };

  return Object.entries(substitutions).reduce(replacement, string);
};

const buildHtmlElement = (dataJsTemplate, substitutions = {}) => {
  const template = document.querySelector(`[data-js-template="${dataJsTemplate}"`);

  if (template) {
    const element = template.content.cloneNode(true).firstElementChild;
    element.innerHTML = replaceAll(element.innerHTML, substitutions);

    initTooltips(element.querySelectorAll('.tooltip'));
    return element;
  }
  return document.createElement('div');
};

export { buildHtmlElement };
