import {
  TiptapBase,
  BulletList,
  ListItem,
  OrderedList,
  Bold,
  Italic,
  Underline,
  Strike,
  Link,
  History,
  AutoHideToolbar,
} from '@/js/util/tiptap/extensions';
import { LinksHandlers, ListsHandlers, TextFormattingHandlers } from '@/js/util/tiptap/handlers';

export default class CompactConfig {
  get extensions() {
    return [
      ...TiptapBase,
      ...BulletList,
      ...ListItem,
      ...OrderedList,
      ...Bold,
      ...Italic,
      ...Underline,
      ...Strike,
      ...Link,
      ...History,
      ...AutoHideToolbar,
    ];
  }

  get handlers() {
    return {
      ...LinksHandlers,
      ...ListsHandlers,
      ...TextFormattingHandlers,
    };
  }
}
