/* global DfApi */

import { Extension } from '@tiptap/core';

const api = () => new DfApi();
const savingMessage = '<span class="spinner"></span> Saving...';
const saveErrorMessage = `<div title="If the error persists please contact customer service">
  <i class="fa-regular fa-circle-exclamation"></i> Autosave error! Retrying...
</div>`;

const AutoSaveNotes = Extension.create({
  name: 'autoSaveNotes',
  onCreate({ editor }) {
    storeProjectId(editor);
    scheduleUpdateSavedAt(editor);
  },
  onUpdate({ editor }) {
    scheduleSaveNotes(editor);
  },
});

const storeProjectId = (editor) => {
  const matches = /\/projects\/(\d+)/.exec(window.location.href);

  if (matches) {
    editor.storage.projectId = matches[1];
  } else {
    alert('⚠️ Project notes is not working properly, please contact customer service');
  }
};

const scheduleUpdateSavedAt = (editor) => {
  const updateInfoTimerId = editor.storage.updateInfoTimerId;

  if (updateInfoTimerId) {
    window.clearTimeout(updateInfoTimerId);
  }
  editor.storage.updateInfoTimerId = window.setInterval(updateSavedAt, 60000, editor);
};

const updateSavedAt = (editor, withIcon = false) => {
  const { lastSavedAt } = editor.storage;
  if (!lastSavedAt) {
    return;
  }

  const icon = withIcon ? '<i class="fa-regular fa-circle-check"></i> ' : '';
  const date = lastSavedAt.toLocaleDateString('en-US');
  const time = lastSavedAt.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
  const message = `${icon}Last saved ${timeElapsed(lastSavedAt)} ago`;

  setSaveInfo(editor, `<div title="${date} at ${time}">${message}</div>`);
};

const scheduleSaveNotes = (editor, timeout = 2000) => {
  const autoSaveTimerId = editor.storage.autoSaveTimerId;

  if (autoSaveTimerId) {
    window.clearTimeout(autoSaveTimerId);
  }
  editor.storage.autoSaveTimerId = window.setTimeout(saveNotes, timeout, editor);
};

const saveNotes = async (editor) => {
  scheduleUpdateSavedAt(editor); // reset the last saved message timer
  setSaveInfo(editor, savingMessage);

  try {
    const content = editor.getHTML();
    const response = await api().saveDesignerNotes(editor.storage.projectId, content);

    response.ok ? saveNotesSuccess(editor, response) : saveNotesError(editor);
  } catch (error) {
    console.error('saveNotes: failed to save notes', error);
    saveNotesError(editor);
  }
};

const setSaveInfo = (editor, message) => {
  const editorContainer = editor.view.dom.closest('[data-controller="tiptap-editor"]');
  const autoSaveElement = editorContainer.querySelector('[data-js-auto-save]');

  autoSaveElement.innerHTML = message;
};

const saveNotesSuccess = async (editor, response) => {
  try {
    const date = await response.json();

    editor.storage.lastSavedAt = date ? new Date(date) : new Date();
  } catch (error) {
    console.error('saveNotesSuccess: error parsing saved date', error);
    editor.storage.lastSavedAt = new Date();
  }
  updateSavedAt(editor, true);
};

const saveNotesError = (editor) => {
  setSaveInfo(editor, saveErrorMessage);
  scheduleSaveNotes(editor, 10000);
};

// TODO: move this method in a shared helper module
const timeElapsed = (lastSavedAt) => {
  const msPerMinute = 1000 * 60;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msElapsed = Date.now() - lastSavedAt;

  if (msElapsed < msPerMinute) {
    return 'seconds';
  } else if (msElapsed < msPerHour) {
    return pluralize(Math.round(msElapsed / msPerMinute), 'minute');
  } else if (msElapsed < msPerDay) {
    return pluralize(Math.round(msElapsed / msPerHour), 'hour');
  } else if (msElapsed < msPerDay * 31) {
    return pluralize(Math.round(msElapsed / msPerDay), 'day');
  } else {
    return 'more than a month';
  }
};

// TODO: move this method in a shared helper module
const pluralize = (count, noun) => {
  return `${count} ${noun}${count === 1 ? '' : 's'}`;
};

export default [AutoSaveNotes];
