import { Extension } from '@tiptap/core';
import { appFor, toolbarFor } from './_get_menu_control';

const toggleVisibility = (toolbar, visible) => {
  requestAnimationFrame(() => toolbar.classList.toggle('hide', !visible));
};

const AutoHideToolbar = Extension.create({
  name: 'AutoHideToolbar',
  onCreate({ editor }) {
    editor.storage._app = appFor(editor);
    editor.storage._toolbar = toolbarFor(editor);
    toggleVisibility(editor.storage._toolbar, false);

    document.addEventListener('mousedown', (event) => {
      const eventEditorApp = event.target.closest('[role="application"]');
      const visible = eventEditorApp === editor.storage._app;

      toggleVisibility(editor.storage._toolbar, visible);
    });
  },
  onFocus({ editor }) {
    toggleVisibility(editor.storage._toolbar, true);
  },
  onBlur({ editor, event }) {
    const target = event.relatedTarget;
    const eventApplication = target?.closest('[role="application"]');

    if (target && eventApplication !== editor.storage._app) {
      toggleVisibility(editor.storage._toolbar, false);
    }
  },
});

export default [AutoHideToolbar];
